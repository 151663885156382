/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const {
          authorFirstName,
          author,
          description,
          linkedInUrl,
          githubUrl,
        } = data.site.siteMetadata
        return (
          <div style={styles.container}>
            <Image
              style={styles.avatar}
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
            />
            <p style={styles.text}>
              Hi, I'm <strong>{authorFirstName}</strong>. I live in Austin,TX
              and work as a {description}.
              <ul>
                <li>
                  <a style={styles.link} href={linkedInUrl}>
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a style={styles.link} href={githubUrl}>
                    Github
                  </a>
                </li>
              </ul>
              <span style={{ margin: "10px 0", display: "block" }}>
                © {new Date().getFullYear()}
              </span>
            </p>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        description
        linkedInUrl
        githubUrl
        authorFirstName
        social {
          twitter
        }
      }
    }
  }
`

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "15px 0",
    borderTop: "1px solid #000",
    paddingTop: 20,
  },
  avatar: {
    borderRadius: "50%",
    height: 50,
    width: 50,
  },
  link: {
    display: "inline-block",
    margin: "5px 0",
  },
  text: {
    marginLeft: 15,
    marginBottom: 0,
    padding: "5px 0",
    maxWidth: 300,
  },
}

export default Bio
