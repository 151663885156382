import React from "react"
import Bio from "../components/bio"
import { Logo } from "../components/logo"

class Layout extends React.Component {
  render() {
    return (
      <div>
        <header style={styles.header}>
          <Logo {...this.props} />
        </header>
        <div style={styles.container}>
          <main>{this.props.children}</main>
          <footer style={styles.footer}>
            <Bio />
          </footer>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    maxWidth: "500px",
    padding: "50px 15px 50px",
  },
  header: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    padding: "10px 10px 0",
    backgroundColor: "#211d1e",
    boxShadow: "#211d1e 0 5px 10px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
}

export default Layout
