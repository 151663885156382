import React from "react"
import { Link } from "gatsby"

export const Logo = props => {
  const rootPath = `${__PATH_PREFIX__}/`
  const { title } = props
  return (
    <h1>
      <Link
        className="logo"
        style={{ fontSize: 21, fontWeight: 700 }}
        to={rootPath}
      >
        {title}
      </Link>
    </h1>
  )
}
